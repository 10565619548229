import React from "react"
import Link from 'gatsby-link';

import notFoundStyles from '../styles/pages/notFound.scss'

import Layout from "../components/layout"
import SEO from "../components/seo"

import { injectIntl } from "gatsby-plugin-intl"

const NotFoundPage = ({ intl }) => (
	<Layout className={notFoundStyles.notFound}>
		<SEO title="404: Not found" />
		<div className={`notFound`}>
			<h1>{intl.formatMessage({ id: "notfound.header" })}</h1>
			<p>{intl.formatMessage({ id: "notfound.description" })}</p>
			<Link
				className={`btn btn--primary`}
				to="/"
			>
			{intl.formatMessage({ id: "notfound.backHome" })}
			</Link>
		</div>
	</Layout>
)
export default injectIntl(NotFoundPage)
